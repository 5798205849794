import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminSingleChapterData } from '@INTERFACES/admin-api/chapters';

import { useAddTagToChapter, useCreateAndAddTagToChapter, useGetAllTags } from '@SERVICES';

import BasicSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';

import styles from './ChapterTagsControls.module.scss';

const cx: CX = classnames.bind(styles);

function ChapterTagsControls(props: Props) {
    const { chapterData } = props;

    const {
        data: tagsData = { data: [] },
        isLoading: isTagsLoading,
    } = useGetAllTags({
        documentId: chapterData.document.id,
    });

    const [
        addTag,
        {
            isSuccess, isError, error,
        },
    ] = useAddTagToChapter();

    const [
        createAndAddTag,
        {
            isSuccess: newTagIsSuccess, isError: newTagIsError, error: newTagError,
        },
    ] = useCreateAndAddTagToChapter();

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

    useEffect(() => {
        if (isSuccess || newTagIsSuccess) {
            notify.info('New tag has been added', {
                toastId: 'add-tag-to-chapter',
            });

            setSelectedTagId(null);
        }
    }, [isSuccess, newTagIsSuccess]);

    useEffect(() => {
        if (isError || newTagIsError) {
            if (((error || newTagError) as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'add-tag-to-chapter-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-tag-to-chapter-error',
                });
            }
        }
    }, [isError, error, newTagIsError, newTagError]);

    const options = useMemo(() => tagsData.data
        .filter((tag) => !chapterData.tags.find((t) => t.id === tag.id))
        .map((tag) => ({
            ...tag,
            value: tag.id,
            label: tag.name,
        })), [chapterData, tagsData.data]);

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedTagId) || null,
        [options, selectedTagId],
    );

    const handleNewTag = (params?: string) => {
        if (!params) return;
        createAndAddTag({
            documentId: chapterData!.document!.id,
            id: chapterData!.id,
            tagName: params,
        });
    };

    const handleTagDelete = (params: { value: number; label: string }) => {
    };

    return (
        <div className={cx('chapter-tags-controls')}>
            <div
                ref={selectWrapperRef}
                className={cx('select-wrapper')}
            >
                <BasicSelect
                    title=""
                    placeholder="Select tag"
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="bottom"
                    isLoading={isTagsLoading}
                    onMenuOpen={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = '5';
                        }
                    }}
                    onMenuClose={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = 'unset';
                        }
                    }}
                    onChange={(val: SingleValue<any>) => {
                        if (val?.id !== null) {
                            addTag({
                                id: chapterData.id,
                                documentId: chapterData.document.id,
                                tagId: val.id,
                            });
                        }
                        setSelectedTagId(val?.id || null);
                    }}
                    onEnterPress={handleNewTag}
                    isDeleteable
                    onDelete={handleTagDelete}
                />
            </div>
        </div>
    );
}

type Props = {
    chapterData: AdminSingleChapterData,
};

export default ChapterTagsControls;
