import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import classnames from 'classnames/bind';

import styles from './accordion.module.scss';

const cx: CX = classnames.bind(styles);

function Accordions(props: AccordionsProps) {
    const {
        title, detailsContain, defaultExpanded = false,
    } = props;

    const Icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
    );

    return (
        <div className={cx('accordion-wrapper')}>
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    className={cx('accordion-summary')}
                    expandIcon={Icon}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails>
                    {detailsContain}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

type AccordionsProps = {
    title: string;
    detailsContain: React.ReactNode;
    defaultExpanded?: boolean;
};

Accordions.defaultProps = {
    defaultExpanded: false,
};

export default Accordions;
