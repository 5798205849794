import React from 'react';
import classnames from 'classnames/bind';

import {
    AdminSingleDocument, useGetAllTags,
} from '@SERVICES';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useDeleteTagModal from '@HOOKS/store/modals/useDeleteTagModal';

import { Typography } from '@mui/material';
import TagItem from '@PAGES/admin/edit-section/components/TagItem';
import styles from './DocumentTags.module.scss';
import DocumentAddTag from '../DocumentAddTag';

const cx: CX = classnames.bind(styles);

function DocumentTags(props: Props) {
    const { documentData } = props;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        data: tagsData = { data: [] },
        isLoading,
    } = useGetAllTags({
        documentId: documentData.data.id,
    });

    const {
        actions: deleteTagModalActions,
    } = useDeleteTagModal(() => true);

    return (
        <div className={cx('document-tags')}>
            <div className={cx('tag-warpper')}>
                <Typography>
                    Tags
                </Typography>
                <div className={cx('tagsWrap')}>
                    {
                        tagsData.data.length > 0
                    && (
                        <div className={cx('tags-list')}>
                            {
                                tagsData.data.map((tag) => {
                                    const key = tag.id;

                                    return (
                                        <div
                                            key={key}
                                            className={cx('tag-item-wrapper')}
                                        >
                                            <TagItem
                                                tag={tag}
                                                isProcessing={isLoading}
                                                locked={!canManageDocumentTags}
                                                onClick={() => {
                                                    deleteTagModalActions.open({
                                                        id: tag.id,
                                                        name: tag.name,
                                                        documentId: documentData.data.id,
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                    }
                    <DocumentAddTag documentId={documentData.data.id} />
                </div>
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentTags;
