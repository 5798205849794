import React, {
    MutableRefObject, RefObject, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';
import { Editor } from '@tinymce/tinymce-react';

import COLOR from '@CONSTANTS/COLOR.constant';
import PDF from '@CONSTANTS/PDF.constant';

import getEditorConfig from '@HELPERS/getEditorConfig';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './BaseEditor.module.scss';

const cx: CX = classnames.bind(styles);

const EDITOR_MARGIN = PDF['pdf-editor-margin'];
const PAGE_HEIGHT = PDF['pdf-page-height'];
const PAGE_WIDTH = PDF['pdf-page-width'];

function BaseEditor(props: Props) {
    const {
        initialValue,
        isLandscape,
        onEditorChange,
    } = props;

    const baseEditorRef: RefObject<HTMLDivElement> = useRef(null);
    const landscapeLabelRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const boundsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const editorRef: MutableRefObject<any> = useRef(null);
    const timer: MutableRefObject<any> = useRef(null);

    const [isEditorReady, setEditorReady] = useState<boolean>(false);

    const editorConfig = getEditorConfig(isLandscape ? 'landscape' : 'portrait');

    function toggleBounds() {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            const { contentAreaContainer } = editorRef.current;

            const { height } = contentAreaContainer.getBoundingClientRect();

            if (height > (PAGE_WIDTH + EDITOR_MARGIN)) {
                boundsRef.current!.style.visibility = 'visible';
            } else {
                boundsRef.current!.style.visibility = 'hidden';
            }
        }, 150);
    }

    function handleEditorChange(content: string) {
        onEditorChange(content);

        if (isLandscape) {
            toggleBounds();
        }
    }

    useLayoutEffect(() => {
        if (isEditorReady) {
            const toxSidebarWrap = baseEditorRef.current!.querySelector('.tox-sidebar-wrap')! as HTMLDivElement;

            toxSidebarWrap.style.position = 'relative';

            const landscapeLabel = document.createElement('div');
            landscapeLabel.classList.add(cx('landscape'));
            landscapeLabel.innerText = 'Landscape';

            const bounds = document.createElement('div');
            bounds.classList.add(cx('bounds'));

            toxSidebarWrap.appendChild(landscapeLabel);
            toxSidebarWrap.appendChild(bounds);

            landscapeLabelRef.current = landscapeLabel;
            boundsRef.current = bounds;

            return () => {
                landscapeLabelRef.current = null;
                boundsRef.current = null;

                landscapeLabel.remove();
                bounds.remove();
            };
        }
    }, [isEditorReady]);

    useLayoutEffect(() => {
        if (isEditorReady) {
            const { contentAreaContainer, contentWindow } = editorRef.current;

            const width = isLandscape ? PAGE_HEIGHT : PAGE_WIDTH;

            contentAreaContainer.style.width = `${width}px`;
            contentAreaContainer.style.maxWidth = `${width}px`;

            contentWindow.document.documentElement.classList.remove('landscape', 'portrait');

            if (isLandscape) {
                landscapeLabelRef.current!.style.visibility = 'visible';

                contentWindow.document.documentElement.classList.add('landscape');

                toggleBounds();
            } else {
                landscapeLabelRef.current!.style.visibility = 'hidden';
                boundsRef.current!.style.visibility = 'hidden';

                contentWindow.document.documentElement.classList.add('portrait');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditorReady, isLandscape]);

    return (
        <>
            <div
                ref={baseEditorRef}
                className={cx('base-editor', {
                    show: isEditorReady,
                })}
            >
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;

                        setEditorReady(true);

                        handleEditorChange(editor.getContent());
                    }}
                    onEditorChange={(content) => {
                        handleEditorChange(content || '');
                    }}
                    initialValue={initialValue}
                    init={{
                        ...editorConfig,
                        height: 700,
                        min_height: 700,
                        paste_as_text: false,
                        paste_data_images: true,
                        paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3',
                        paste_retain_style_properties: 'all',
                    }}
                />
            </div>
            {
                !isEditorReady
                && (
                    <div className={cx('editor-loading')}>
                        <CircularProgressIndicator
                            color={COLOR['marriott-primary']}
                            size={40}
                            thickness={4}
                        />
                    </div>
                )
            }
        </>
    );
}

BaseEditor.propTypes = {
    initialValue: PropTypes.string.isRequired,
    isLandscape: PropTypes.bool.isRequired,
    onEditorChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseEditor.propTypes>;

export default BaseEditor;
