import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function AlertDialog(props: AlertDialogProps) {
    const {
        open, handleClose, title, dialogContent, size,
    } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={size}
        >
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    title: '',
    size: 'sm',
};

interface AlertDialogProps {
    open: boolean;
    handleClose: () => void;
    title?: string;
    dialogContent: React.ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xl';

}

export default AlertDialog;
