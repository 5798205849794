import * as React from 'react';
import TextField from '@mui/material/TextField';

function SearchBar(props: SearchBarProps) {
    const { value, handleChange, placeholder } = props;
    return (
        <TextField
            placeholder={placeholder}
            id="outlined-controlled"
            value={value}
            onChange={handleChange}
        />
    );
}

SearchBar.defaultProps = {
    placeholder: 'Search...',
};

type SearchBarProps = {
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
};

export default SearchBar;
