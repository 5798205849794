import React, { useId } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Select, {
    MenuPlacement, Options, SingleValue, components,
} from 'react-select';

import classnames from 'classnames/bind';

import COLOR from '@CONSTANTS/COLOR.constant';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import { selectBaseStyles } from '@COMPONENTS/COMMON/inputs/select/config';
import NoOptionsMessageBase from '@COMPONENTS/COMMON/inputs/select/base/NoOptionsMessageBase';
import ClearIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/ClearIndicatorBase';
import DropdownIndicatorBase from '@COMPONENTS/COMMON/inputs/select/base/DropdownIndicatorBase';
import DropdownTrash from '@COMPONENTS/COMMON/inputs/select/base/DropdownTrash';
import styles from './BasicSelect.module.scss';

const cx: CX = classnames.bind(styles);

function BasicSelect(props: Props) {
    const {
        title,
        placeholder,
        options,
        value,
        isClearable,
        isSearchable,
        isDisabled,
        isLoading,
        menuPlacement,
        onChange,
        onMenuOpen,
        onMenuClose,
        onEnterPress,
        isDeleteable,
        onDelete,
    } = props;

    const inputId = useId();
    const [inputValue, setInputValue] = React.useState('');

    function handleChange(val: any) {
        onChange(val);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const inputValueExists = options.some((option) => option.label.includes(inputValue));
        if (event.key === 'Enter' && !inputValueExists && onEnterPress) {
            onEnterPress(inputValue);
            if (event.target instanceof HTMLElement) {
                event.target.blur();
            }
            event.preventDefault();
        }
    };

    const handleDelete = (option: { value: string; label: string }) => {
        onDelete(option);
    };

    const CustomOption = React.memo((optionProps: any) => (
        isDeleteable ? <DropdownTrash {...optionProps} onDelete={handleDelete} /> : (
            <components.Option {...optionProps} />
        )
    ));
    CustomOption.displayName = 'CustomOption';

    return (
        <div className={cx('basic-select')}>
            <label htmlFor={inputId}>
                <div className={cx('title')}>
                    {title}
                </div>
                {
                    isLoading
                    && (
                        <div className={cx('loading-wrapper')}>
                            <CircularProgressIndicator
                                size={12}
                                thickness={2}
                                color={COLOR['marriott-light-grey']}
                            />
                        </div>
                    )
                }
            </label>
            <Select
                inputId={inputId}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                options={options}
                isClearable={isClearable}
                isSearchable={isSearchable}
                isDisabled={isDisabled}
                placeholder={placeholder}
                menuPlacement={menuPlacement as MenuPlacement}
                menuShouldScrollIntoView={false}
                value={value}
                onChange={(val: any) => handleChange(val)}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                unstyled
                components={{
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: NoOptionsMessageBase,
                    ClearIndicator: ClearIndicatorBase,
                    DropdownIndicator: DropdownIndicatorBase,
                    Option: CustomOption,
                }}
                styles={selectBaseStyles}
                classNames={{
                    menuList: () => cx('menu-list'),
                    control: () => cx('custom-control'),
                    option: () => cx('custom-option'),
                    dropdownIndicator: () => cx('custom-dropdown-icon'),
                    menu: () => cx('custom-menu'),
                }}
            />
        </div>
    );
}

BasicSelect.defaultProps = {
    title: '',
    placeholder: 'Select...',
    isClearable: true,
    isSearchable: false,
    isDisabled: false,
    isLoading: false,
    menuPlacement: 'auto',
    onMenuOpen: () => {
    },
    onMenuClose: () => {
    },
    onEnterPress: () => {},
    onDelete: (data: { value: number; label: string }) => {},
    isDeleteable: false,
};

BasicSelect.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    onMenuClose: PropTypes.func,
    menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
    onEnterPress: PropTypes.func,
    onDelete: PropTypes.func,
    isDeleteable: PropTypes.bool,
};

type Props = InferProps<typeof BasicSelect.propTypes> & typeof BasicSelect.defaultProps & {
    options: Options<any>,
    value: SingleValue<any>
    onEnterPress?: (inputValue: string) => void,
};

export default BasicSelect;
