import React, {
    RefObject, useEffect, useMemo, useRef, useState,
} from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SingleValue } from 'react-select';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { AdminSingleSectionData } from '@INTERFACES/admin-api/sections';

import {
    useAddTagToSection,
    useCreateAndAddTagToSection,
    useGetAllTags,
} from '@SERVICES';

import BasicSelect from 'src/view/COMPONENTS/COMMON/inputs/select/inputs/BasicSelect';

import styles from './SectionTagsControls.module.scss';

const cx: CX = classnames.bind(styles);

function SectionTagsControls(props: Props) {
    const { sectionData } = props;

    const { data: tagsData = { data: [] }, isLoading: isTagsLoading } = useGetAllTags({
        documentId: sectionData.document.id,
    });

    const [addTag, { isSuccess, isError, error }] = useAddTagToSection();

    const [
        createAndAddTag,
        {
            isSuccess: newTagIsSuccess,
            isError: newTagIsError,
            error: newTagError,
        },
    ] = useCreateAndAddTagToSection();

    const selectWrapperRef: RefObject<HTMLDivElement> = useRef(null);

    const [selectedTagId, setSelectedTagId] = useState<number | null>(null);

    useEffect(() => {
        if (isSuccess || newTagIsSuccess) {
            notify.info('New tag has been added', {
                toastId: 'add-tag-to-section',
            });

            setSelectedTagId(null);
        }
    }, [isSuccess, newTagIsSuccess]);

    useEffect(() => {
        if (isError || newTagIsError) {
            if (
                ((error || newTagError) as FetchBaseQueryError).status === 403
            ) {
                notify.error('Permission denied', {
                    toastId: 'add-tag-to-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'add-tag-to-section-error',
                });
            }
        }
    }, [isError, error, newTagIsError, newTagError]);

    const options = useMemo(
        () => tagsData.data
            .filter((tag) => !sectionData.tags.find((t) => t.id === tag.id))
            .map((tag) => ({
                ...tag,
                value: tag.id,
                label: tag.name,
            })),
        [sectionData, tagsData.data],
    );

    const value = useMemo(
        () => options.find((opt) => opt.id === selectedTagId) || null,
        [options, selectedTagId],
    );

    const handleNewTag = (params?: string) => {
        if (!params) return;
        createAndAddTag({
            documentId: sectionData!.document!.id,
            chapterId: sectionData!.chapter!.id,
            id: sectionData!.id,
            tagName: params,
        });
    };

    const handleTagDelete = (params: { value: number; label: string }) => {
    };

    return (
        <div className={cx('section-tags-controls')}>
            <div ref={selectWrapperRef} className={cx('select-wrapper')}>
                <BasicSelect
                    title=""
                    placeholder="Add new tag ..."
                    isSearchable
                    options={options}
                    value={value}
                    menuPlacement="bottom"
                    isLoading={isTagsLoading}
                    onMenuOpen={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = '5';
                        }
                    }}
                    onMenuClose={() => {
                        if (selectWrapperRef.current) {
                            selectWrapperRef.current.style.zIndex = 'unset';
                        }
                    }}
                    onChange={(val: SingleValue<any>) => {
                        if (val?.id) {
                            addTag({
                                id: sectionData.id,
                                chapterId: sectionData.chapter.id,
                                tagId: val.id,
                            });
                        }
                        setSelectedTagId(val?.id || null);
                    }}
                    onEnterPress={handleNewTag}
                    isDeleteable
                    onDelete={handleTagDelete}
                />
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData;
};

export default SectionTagsControls;
