import React, { Suspense, lazy, FC } from 'react';
import classnames from 'classnames/bind';
import styles from './iconLogo.module.scss';

const cx: CX = classnames.bind(styles);

interface IconLoaderProps {
    iconName: string;
}

type IconComponentType = React.LazyExoticComponent<FC<React.SVGProps<SVGSVGElement>>>;
type IconMap = Record<string, IconComponentType>;

const iconMap: IconMap = {
    'order-icon': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    Aloft: lazy(() => import('@LOGOS/Aloft.svg').then((module) => ({ default: module.ReactComponent }))),
    Autograph: lazy(() => import('@LOGOS/Autograph.svg').then((module) => ({ default: module.ReactComponent }))),
    Bulgari: lazy(() => import('@LOGOS/Bulgari.svg').then((module) => ({ default: module.ReactComponent }))),
    Courtyard: lazy(() => import('@LOGOS/Courtyard.svg').then((module) => ({ default: module.ReactComponent }))),
    'Delta-Hotels-by-Marriott': lazy(() => import('@LOGOS/Delta-Hotels-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Design-Hotels': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    Edition: lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    Element: lazy(() => import('@LOGOS/Element.svg').then((module) => ({ default: module.ReactComponent }))),
    'Fairfield-By-Marriott': lazy(() => import('@LOGOS/Fairfield-By-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Four-Points-by-Sheraton': lazy(() => import('@LOGOS/Four-Points-by-Sheraton.svg').then((module) => ({ default: module.ReactComponent }))),
    'Gaylord-Hotels': lazy(() => import('@LOGOS/Gaylord-Hotels.svg').then((module) => ({ default: module.ReactComponent }))),
    'Independent-Marriott': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Independent-Starwood': lazy(() => import('@LOGOS/Independent-Starwood.svg').then((module) => ({ default: module.ReactComponent }))),
    'JW-Marriott': lazy(() => import('@LOGOS/JW-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Le-Meridien': lazy(() => import('@LOGOS/Le-Meridien.svg').then((module) => ({ default: module.ReactComponent }))),
    'Luxury-Collection': lazy(() => import('@LOGOS/Luxury-Collection.svg').then((module) => ({ default: module.ReactComponent }))),
    Marriott: lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Marriott-Executive-Apartments': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    Moxy: lazy(() => import('@LOGOS/Moxy.svg').then((module) => ({ default: module.ReactComponent }))),
    Protea: lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    Renaissance: lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Residence-Inn-by-Marriott': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'Ritz-Carlton-Club': lazy(() => import('@LOGOS/Ritz-Carlton-Club.svg').then((module) => ({ default: module.ReactComponent }))),
    Sheraton: lazy(() => import('@LOGOS/Sheraton.svg').then((module) => ({ default: module.ReactComponent }))),
    'St-Regis': lazy(() => import('@LOGOS/St-Regis.svg').then((module) => ({ default: module.ReactComponent }))),
    'The-Ritz-Carlton': lazy(() => import('@LOGOS/The-Ritz-Carlton.svg').then((module) => ({ default: module.ReactComponent }))),
    'Tribute-Portfolio': lazy(() => import('@LOGOS/AC-by-Marriott.svg').then((module) => ({ default: module.ReactComponent }))),
    'W-Hotel': lazy(() => import('@LOGOS/W-Hotel.svg').then((module) => ({ default: module.ReactComponent }))),
    Westin: lazy(() => import('@LOGOS/Westin.svg').then((module) => ({ default: module.ReactComponent }))),
};

function IconLoader(props: IconLoaderProps) {
    const { iconName } = props;
    const IconComponent = iconMap[iconName] || iconMap['order-icon'];

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className={cx('image-wrap')}>
                <IconComponent width="100%" height="100%" />
            </div>
        </Suspense>
    );
}

export default IconLoader;
