import React, { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { TextField } from '@mui/material';
import classnames from 'classnames/bind';
import { useCreateTag } from '@SERVICES';
import { notify } from '@NOTIFICATION/Notificator';
import styles from './Document.module.scss';

const cx: CX = classnames.bind(styles);
const successMessage = 'New tag has been created!';
const itemName = 'Tag';
function DocumentAddTag(props: DocumentAddTagProps) {
    const { documentId } = props;
    const [name, setName] = React.useState('');

    const [
        createTag,
        {
            isSuccess, isError, error,
        },
    ] = useCreateTag();

    useEffect(() => {
        if (isSuccess) {
            notify.info(successMessage, {
                toastId: `add-new-${itemName.toLowerCase()}`,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            if ((error as FetchBaseQueryError).status === 400) {
                notify.error(`${itemName} with this name already exists`, {
                    toastId: `add-new-${itemName.toLowerCase()}-error`,
                });
            } else if ((error as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: `add-new-${itemName.toLowerCase()}-permission-error`,
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'something-wrong',
                });
            }
        }
    }, [isError, error]);

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            createTag({
                documentId,
                name,
            });
            setName('');
        }
    };

    return (
        <div className={cx('add-tag-wrapper')}>
            <TextField
                placeholder="Add new tag..."
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value);
                }}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
}

interface DocumentAddTagProps {
    documentId: number;
}

export default DocumentAddTag;
