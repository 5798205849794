import React from 'react';

import classnames from 'classnames/bind';

import { AdminSingleChapterData, AdminSingleChapterMeta } from '@INTERFACES/admin-api/chapters';

import useDocumentPermissions from '@HOOKS/useDocumentPermissions';
import useChapterNameModal from '@HOOKS/store/modals/useChapterNameModal';
import usePreviewChapterModal from '@HOOKS/store/modals/usePreviewChapterModal';

import Lock from '@COMPONENTS/SHARED/Lock';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import styles from './ChapterName.module.scss';
import ChapterTags from '../ChapterTags';

const cx: CX = classnames.bind(styles);

function ChapterName(props: Props) {
    const { chapterData, chapterMeta, actionButton } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: chapterData.document.owner,
        permissions: chapterMeta.permissions,
    });

    const {
        actions,
    } = useChapterNameModal(() => true);

    const {
        actions: previewChapterModalActions,
    } = usePreviewChapterModal(() => true);

    return (
        <div className={cx('chapter-name')}>
            <div className={cx('content-wrapper')}>
                <div className={cx('name')}>
                    {`Chapter - ${chapterData.name}`}
                </div>
                <div className={cx('action-button-wrapper')}>
                    {
                        !canEditDocument
                            ? (
                                <Lock size="medium" />
                            ) : (
                                <ActionButton
                                    buttonText="Rename"
                                    tooltipTitle="Edit chapter name"
                                    variant="outlined"
                                    handleClick={() => {
                                        actions.open({
                                            chapterData,
                                        });
                                    }}
                                    icon="edit"
                                />
                            )
                    }

                    <ActionButton
                        buttonText="Preview"
                        variant="outlined"
                        handleClick={() => {
                            previewChapterModalActions.open({
                                chapterData,
                                canGenerateDocument,
                            });
                        }}
                        tooltipTitle="Chapter preview"
                        icon="preview"
                    />
                    {actionButton}
                </div>
            </div>
            <div className={cx('body-wrapper')}>
                <ChapterTags
                    chapterData={chapterData}
                    chapterMeta={chapterMeta}
                />
            </div>
        </div>
    );
}

ChapterName.defaultProps = {
    actionButton: null,
};

type Props = {
    chapterData: AdminSingleChapterData,
    chapterMeta: AdminSingleChapterMeta,
    actionButton?: React.ReactNode,
};

export default ChapterName;
