import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { BrandStep, BrandStepData } from '@REDUCERS';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllBrands } from '@SERVICES';

import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';
import Grid from '@COMPONENTS/SHARED/Grid';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';

import SearchBar from '@COMPONENTS/COMMON/searchbar/searchBar';
import styles from './Brand.module.scss';

const cx: CX = classnames.bind(styles);

function BrandPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const {
        data: brandsData = [], isLoading, isError, error,
    } = useGetAllBrands();
    const [brandName, setBrandName] = React.useState('');

    const navigate = useNavigate();

    const filterList = React.useMemo(() => {
        if (!brandName) return brandsData;
        return brandsData.filter(({ name }: any) => name.toLowerCase().includes(brandName.toLowerCase()));
    }, [brandName, brandsData]);

    function handleClick(id: number, name: string) {
        const data: BrandStepData = {
            id,
            name,
        };

        documentGenerationStepsActions.apply({
            id: GenerationSteps.BRAND,
            isComplete: true,
            data,
        });

        navigate(ROUTES.region.path);
    }

    function getHighlighted(id: number) {
        const step: BrandStep = documentGenerationStepsServices.getStepData(GenerationSteps.BRAND);

        return step.data?.id === id;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBrandName(e.target.value);
    };

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={130}
                    offsetBottom={100}
                >
                    <div className={cx('brand-header')}>
                        <h1>Brand</h1>
                        <SearchBar value={brandName} handleChange={handleChange} placeholder="Search brand..." />
                    </div>
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Grid
                            hasSidebar
                        >
                            {
                                filterList.map((brand) => {
                                    const key = brand.id;

                                    const highlighted = getHighlighted(brand.id);

                                    return (
                                        <SquareButton
                                            key={key}
                                            highlighted={highlighted}
                                            title={brand.name}
                                            onClick={() => handleClick(
                                                brand.id,
                                                brand.name,
                                            )}
                                            img={brand.name.replaceAll(' ', '-')}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default BrandPage;
