import React, { useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classnames from 'classnames/bind';

import { notify } from '@NOTIFICATION/Notificator';

import { useDeleteTagFromSection } from '@SERVICES';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import SectionTagsControls from '@PAGES/admin/edit-section/components/SectionTagsControls';
import TagItem from '@PAGES/admin/edit-section/components/TagItem';

import { Typography } from '@mui/material';
import styles from './SectionTags.module.scss';

const cx: CX = classnames.bind(styles);

function SectionTags(props: Props) {
    const { sectionData, sectionMeta } = props;

    const { tags } = sectionData;

    const {
        canManageDocumentTags,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagOriginalArgs,
        },
    ] = useDeleteTagFromSection();

    useEffect(() => {
        if (isDeleteTagSuccess) {
            notify.info('Tag has been deleted', {
                toastId: 'delete-tag-from-section',
            });
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) {
            if ((deleteTagError as FetchBaseQueryError).status === 403) {
                notify.error('Permission denied', {
                    toastId: 'delete-tag-from-section-permission-error',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'delete-tag-from-section-error',
                });
            }
        }
    }, [isDeleteTagError, deleteTagError]);

    return (
        <div className={cx('section-tags', 'container')}>
            <div className={cx('tag-warpper')}>
                <Typography>
                    Tags
                </Typography>
                <div className={cx('tagsWrap')}>
                    {
                        tags.length > 0
                            && (
                                <div className={cx('tags-list')}>
                                    {
                                        tags.map((tag) => {
                                            const key = tag.id;

                                            const isProcessing = deleteTagOriginalArgs?.tagId === tag.id && isDeleteTagLoading;

                                            return (
                                                <div
                                                    key={key}
                                                    className={cx('tag-item-wrapper')}
                                                >
                                                    <TagItem
                                                        tag={tag}
                                                        isProcessing={isProcessing}
                                                        locked={!canManageDocumentTags}
                                                        onClick={() => {
                                                            deleteTag({
                                                                chapterId: sectionData.chapter.id,
                                                                id: sectionData.id,
                                                                tagId: tag.id,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                    }
                    <SectionTagsControls
                        sectionData={sectionData}
                    />
                </div>
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
};

export default SectionTags;
