import React from 'react';

import classnames from 'classnames/bind';

import { AdminSingleSectionData, AdminSingleSectionMeta } from '@INTERFACES/admin-api/sections';

import useSectionNameModal from '@HOOKS/store/modals/useSectionNameModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import Lock from '@COMPONENTS/SHARED/Lock';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import styles from './SectionName.module.scss';
import SectionTags from '../SectionTags';

const cx: CX = classnames.bind(styles);

function SectionName(props: Props) {
    const {
        sectionData, sectionMeta, actionButton, bodyCotent,
    } = props;

    const {
        canEditDocument,
    } = useDocumentPermissions({
        documentOwnerId: sectionData.document.owner,
        permissions: sectionMeta.permissions,
    });

    const {
        actions,
    } = useSectionNameModal(() => true);

    return (
        <div className={cx('section-name', 'container')}>
            <div className={cx('content-wrapper')}>
                <div className={cx('name')}>
                    {`Section - ${sectionData?.name}`}
                </div>
                <div className={cx('action-button-wrapper')}>
                    {
                        !canEditDocument
                            ? (
                                <Lock size="medium" />
                            ) : (

                                <ActionButton
                                    tooltipTitle="Edit section name"
                                    buttonText="Rename"
                                    variant="outlined"
                                    handleClick={() => {
                                        actions.open({
                                            sectionData,
                                        });
                                    }}
                                    icon="edit"
                                />
                            )
                    }
                    {actionButton}
                </div>
            </div>
            <div className={cx('body-wrapper')}>
                <SectionTags
                    sectionData={sectionData}
                    sectionMeta={sectionMeta}
                />
                {bodyCotent && bodyCotent}
            </div>
        </div>
    );
}

type Props = {
    sectionData: AdminSingleSectionData,
    sectionMeta: AdminSingleSectionMeta,
    actionButton?: React.ReactNode,
    bodyCotent?: React.ReactNode
};

SectionName.defaultProps = {
    actionButton: null,
    bodyCotent: null,
};

export default SectionName;
