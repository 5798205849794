import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';

import { AdminSingleDocumentChapter, AdminSingleDocumentMetaDocument } from '@SERVICES';

import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import styles from './DocumentSidebar.module.scss';

const cx: CX = classnames.bind(styles);

function DocumentSidebar(props: Props) {
    const { documents, chapters } = props;

    const { id } = useParams();
    const navigate = useNavigate();
    const activeDocument = documents.find(({ id: docId }) => docId === Number(id));

    return (
        <div className={cx('document-sidebar')}>
            <div className={cx('container-left')}>
                <div className={cx('documents-title')}>
                    {activeDocument?.name}
                </div>
                <div className={cx('documents-list')}>
                    {
                        chapters?.map(({ id: chId, name, sections }) => {
                            const content = (
                                <ul>
                                    {sections?.map(({ name: secName, id: secId }) => (
                                        <li key={secName}>
                                            <div
                                                role="button"
                                                tabIndex={0}
                                                onClick={() => {
                                                    navigate(ROUTES.admin.sections.id.get(secId));
                                                }}
                                                className={cx('charpter-link')}
                                            >
                                                {secName}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            );
                            return <Accordions key={chId} title={name} detailsContain={content} />;
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    documents: AdminSingleDocumentMetaDocument[],
    chapters: AdminSingleDocumentChapter[]
};

export default DocumentSidebar;
