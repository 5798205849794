import React from 'react';

import classnames from 'classnames/bind';

import useDocumentNameModal from '@HOOKS/store/modals/useDocumentNameModal';
import usePreviewDocumentModal from '@HOOKS/store/modals/usePreviewDocumentModal';
import useDocumentPermissions from '@HOOKS/useDocumentPermissions';

import { AdminSingleDocument } from '@SERVICES';

import Lock from '@COMPONENTS/SHARED/Lock';

import ActionButton from '@COMPONENTS/COMMON/buttons/ActionButton/actionButton';
import styles from './DocumentName.module.scss';
import DocumentTags from '../DocumentTags';

const cx: CX = classnames.bind(styles);

function DocumentName(props: Props) {
    const { documentData } = props;

    const {
        canEditDocument,
        canGenerateDocument,
    } = useDocumentPermissions({
        documentOwnerId: documentData.data.owner.id,
        permissions: documentData.meta.permissions,
    });

    const {
        actions,
    } = useDocumentNameModal(() => true);

    const {
        actions: previewDocumentModalActions,
    } = usePreviewDocumentModal(() => true);

    const locked = !canEditDocument;

    return (
        <div className={cx('document-name', 'container')}>
            <div className={cx('name-wrapper')}>
                <div className={cx('name')}>
                    {`Document - ${documentData.data.name}`}
                </div>
                <div className={cx('action-button-wrapper')}>
                    {
                        locked
                            ? (
                                <Lock size="medium" />
                            ) : (
                                <ActionButton
                                    buttonText="Rename"
                                    tooltipTitle="Edit document name"
                                    variant="outlined"
                                    handleClick={() => {
                                        actions.open({
                                            documentData: documentData.data,
                                        });
                                    }}
                                    icon="edit"
                                />
                            )
                    }
                    <ActionButton
                        icon="preview"
                        buttonText="Preview"
                        tooltipTitle="Document preview"
                        variant="outlined"
                        handleClick={() => {
                            previewDocumentModalActions.open({
                                documentData: documentData.data,
                                canGenerateDocument,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={cx('document-owner')}>
                <div className={cx('owner')}>
                    Owner:
                </div>
                <div className={cx('name')}>
                    {documentData.data.owner.fullName || documentData.data.owner.email}
                </div>
            </div>
            <div className={cx('body-wrapper')}>
                <DocumentTags documentData={documentData} />
            </div>
        </div>
    );
}

type Props = {
    documentData: AdminSingleDocument,
};

export default DocumentName;
